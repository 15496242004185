<template>
    <v-container fluid class="pt-0 align-start fill-height">
      <v-row style="height: 100%">
        <v-col cols="12" md="12">
          <v-card-text class="pa-0" style="height: 100%">
            <v-tabs class="custom-tabs" color="success" style="height: 100%">
              <v-tab class="text-left justify-start">
                Монетизація (подання інформації)
              </v-tab>

              <v-tab class="text-left justify-start">
                Монетизація (реєстр із оплатами)
              </v-tab>

              <v-tab-item class="pa-0" style="height: 100%">
                <v-row style="height: 100%">
                  <MainModal
                      :main="{ component: 'MonetaryDebt', title: 'Банки' }"
                      :button="{ iconActivator: false, buttonHidden: true }"
                      :item="selected_debt_item"
                      :max-width="1240"
                      :min-height="200"
                      :modal="debt_dialog_id === selected_debt_item.id"
                      @updateItemModal="updateItemModal"
                      @crud="afterCrudModal"
                  ></MainModal>
                  <v-col cols="12" md="12">
                    <v-row justify="center" style="height: 100%; display: flex; flex-direction: column; flex-wrap: nowrap">
                      <v-col cols="12" style="flex: 0">
                        <v-card>
                          <v-toolbar tile elevation="0" outlined dense style="border: none"
                                     :height="$vuetify.breakpoint.xs ? 90 : 54">
                            <template v-if="$vuetify.breakpoint.smAndUp">
                              <v-toolbar-title class="font-weight-medium" style="font-size: 1.1rem">Реєстр за {{ current_month | formatDate('MMMM YYYY') }}</v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-toolbar-items class="shrink">
                                <v-row class="align-center">
                                  <MonthComponent v-model="current_month" :class_="'mr-2'" :label="`Місяць`"/>
                                  <MainModal
                                      :main="{ component: 'MonetaryImport', title: 'Монетизація обробка файлів' }"
                                      :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
                                      :item="{ isDebt: true }"
                                      @updateItemModal="updateItemModal"
                                      @crud="afterCrudModal"
                                  />
                                </v-row>
                              </v-toolbar-items>
                            </template>
                            <template v-else>
                              <div style="width: 100%">
                                <div class="d-flex mb-2">
                                  <v-toolbar-title class="font-weight-medium" style="font-size: 1.3rem">
                                    Реєстр за {{ current_month | formatDate('MMMM YYYY') }} року
                                  </v-toolbar-title>
                                </div>
                                <div class="d-flex">
                                  <MonthComponent v-model="current_month" :class_="'mr-2'" :label="`Місяць`"/>
                                  <v-spacer></v-spacer>
                                </div>
                              </div>
                            </template>
                          </v-toolbar>
                        </v-card>
                      </v-col>
                      <v-col cols="12" style="flex: 1">
                        <template v-if="debt_items.length">
                          <v-card
                              v-for="(item, idx) in debt_items" :key="idx" tile elevation="2"
                              class="mb-2 d-flex flex-wrap flex-row row-card"
                              :style="item.error ? 'border-left: 3px solid #de5454' : 'border-left: 3px solid #5bb55f'"
                              @click.stop="openDocument(item)"
                          >
                            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                              <div>Вид монетизації</div>
                              <div>
                                <v-chip class="px-6" :color="item.monetary_type === 'privilege' ? 'success' : 'secondary'" dark small tag="b">
                                  {{ getMonetaryTypeTranslate(item.monetary_type, false) }}
                                </v-chip>
                              </div>
                            </v-col>
                            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
                              <div>Дата створення</div>
                              <div>{{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}</div>
                            </v-col>
                            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                              <div>Період файлу</div>
                              <div style="text-transform: capitalize">{{ item.period | formatDate('MMMM YYYY') }}</div>
                            </v-col>
                            <v-col cols="12" md="3" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                              <div>Файл</div>
                              <div>{{ item.filename }}</div>
                            </v-col>
                            <v-col cols="12" md="3" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                              <div>Послуга</div>
                              <div>
                                <span>{{ getServiceName(item.service_id) }}</span></div>
                            </v-col>
                          </v-card>
                        </template>
                        <template v-else>
                          <v-card style="height: 100%; display: flex; align-items: center; justify-content: center">
                            <div class="wrapper align-center text-center">
                              <v-icon size="200" color="success" style="opacity: .24">mdi-clock</v-icon>
                              <div class="title grey--text text--darken-2 px-4">Документи для відображення відсутні <br> Спершу створіть документ</div>
                            </div>
                          </v-card>
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item class="pa-0" style="height: 100%">
                <v-row style="height: 100%">
                  <MainModal
                      :main="{ component: 'MonetaryPay', title: 'Банки' }"
                      :button="{ iconActivator: false, buttonHidden: true }"
                      :item="selected_pay_item"
                      :max-width="1240"
                      :min-height="200"
                      :modal="pay_dialog_id === selected_pay_item.id"
                      @updateItemModal="updateItemModalPay"
                      @crud="afterCrudModal"
                  ></MainModal>
                  <v-col cols="12" md="12">
                    <v-row justify="center" style="height: 100%; display: flex; flex-direction: column; flex-wrap: nowrap">
                      <v-col cols="12" style="flex: 0">
                        <v-card>
                          <v-toolbar tile elevation="0" outlined dense style="border: none"
                                     :height="$vuetify.breakpoint.xs ? 90 : 54">
                            <template v-if="$vuetify.breakpoint.smAndUp">
                              <v-toolbar-title class="font-weight-medium" style="font-size: 1.1rem">Реєстр за {{ current_month_pay | formatDate('MMMM YYYY') }}</v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-toolbar-items class="shrink">
                                <v-row class="align-center">
                                  <MonthComponent v-model="current_month_pay" :class_="'mr-2'" :label="`Місяць`"/>
                                  <MainModal
                                      :main="{ component: 'MonetaryImport', title: 'Монетизація обробка файлів' }"
                                      :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
                                      :item="{ isDebt: false }"
                                      @updateItemModal="updateItemModalPay"
                                      @crud="afterCrudModal"
                                  />
                                </v-row>
                              </v-toolbar-items>
                            </template>
                            <template v-else>
                              <div style="width: 100%">
                                <div class="d-flex mb-2">
                                  <v-toolbar-title class="font-weight-medium" style="font-size: 1.3rem">
                                    Реєстр за {{ current_month_pay | formatDate('MMMM YYYY') }} року
                                  </v-toolbar-title>
                                </div>
                                <div class="d-flex">
                                  <MonthComponent v-model="current_month_pay" :class_="'mr-2'" :label="`Місяць`"/>
                                  <v-spacer></v-spacer>
                                </div>
                              </div>
                            </template>
                          </v-toolbar>
                        </v-card>
                      </v-col>
                      <v-col cols="12" style="flex: 1">
                        <template v-if="pay_items.length">
                          <v-card
                              v-for="(item, idx) in pay_items" :key="idx" tile elevation="2"
                              class="mb-2 d-flex flex-wrap flex-row row-card"
                              :style="item.error ? 'border-left: 3px solid #de5454' : 'border-left: 3px solid #5bb55f'"
                              @click.stop="openDocument(item)"
                          >
                            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                              <div>Вид монетизації</div>
                              <div>
                                <v-chip class="px-6" :color="item.monetary_type === 'privilege' ? 'success' : 'secondary'" dark small tag="b">
                                  {{ getMonetaryTypeTranslate(item.monetary_type, false) }}
                                </v-chip>
                              </div>
                            </v-col>
                            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
                              <div>Дата створення</div>
                              <div>{{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}</div>
                            </v-col>
                            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                              <div>Період файлу</div>
                              <div style="text-transform: capitalize">{{ item.period | formatDate('MMMM YYYY') }}</div>
                            </v-col>
                            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                              <div>Файл</div>
                              <div>{{ item.filename }}</div>
                            </v-col>
                            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                              <div>Послуга</div>
                              <div>
                                <span>{{ getServiceName(item.service_id) }}</span></div>
                            </v-col>
                            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
                              <div>Оплачено</div>
                              <div>
                                <span>{{ item.pay_sum }}</span></div>
                            </v-col>
                          </v-card>
                        </template>
                        <template v-else>
                          <v-card style="height: 100%; display: flex; align-items: center; justify-content: center">
                            <div class="wrapper align-center text-center">
                              <v-icon size="200" color="success" style="opacity: .24">mdi-clock</v-icon>
                              <div class="title grey--text text--darken-2 px-4">Документи для відображення відсутні <br> Спершу створіть документ</div>
                            </div>
                          </v-card>
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

            </v-tabs>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import monetartAPI from '@/utils/axios/monetary'
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapActions, mapGetters} from "vuex";
import {GET_CURRENT_MONTH} from "@/store/actions/work_month";
import {getMonetaryTypeTranslate} from "@/utils/icons";
import {SELECT_SERVICES_WITHOUT_ALL_SERVICE} from "@/store/actions/services";

export default {
  name: "PaymentsList",
  components: {
    MonthComponent: () => import("@/components/MonthComponent"),
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      current_month_: 'getCurrentMonth',
      services: 'getServicesSelectWithoutAll'
    })
  },
  data() {
    return {
      current_month: null,
      current_month_pay: null,
      debt_items: [],
      pay_items: [],
      debt_dialog_id: 0,
      pay_dialog_id: 0,
      selected_debt_item: {},
      selected_pay_item: {},
    }
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES_WITHOUT_ALL_SERVICE
    }),
    getMonetaryTypeTranslate,
    updateItemModal() {
      this.selected_debt_item = {}
      this.debt_dialog_id = 0
    },
    updateItemModalPay() {
      this.selected_pay_item = {}
      this.pay_dialog_id = 0
    },
    afterCrudModal(payload) {
      if (payload.method === 'fetch') {
        if (payload.isDebt) {
          this.fetchDebtHeaders()
        } else {
          this.fetchPayHeaders()
        }
      }
    },
    fetchDebtHeaders(month=null) {
      monetartAPI.get_monetary_debt_headers(!month ? this.current_month_ : month)
        .then(response => response.data)
        .then(data => {
          this.debt_items = data
        })
        .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    fetchPayHeaders(month=null) {
      monetartAPI.get_monetary_pay_headers(!month ? this.current_month_ : month)
        .then(response => response.data)
        .then(data => {
          this.pay_items = data
        })
        .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    openDocument(payload) {
      this.selected_debt_item = payload
      this.debt_dialog_id = payload.id
    },
    openDocumentPay(payload) {
      this.selected_pay_item = payload
      this.pay_dialog_id = payload.id
    },
    getServiceName(service_id) {
      if (this.services) {
        return (this.services.find(item => item.value === service_id) || {}).text || ''
      }
      return ''
    },
    createDocument() {
      console.log('create doc')
    }
  },
  watch: {
    current_month(payload) {
      this.fetchDebtHeaders(payload)
    },
    current_month_pay(payload) {
      this.fetchPayHeaders(payload)
    }
  },
  created() {
    this.fetchServices()
      .then(response => response)
      .then(() => {
        this.$store.dispatch(GET_CURRENT_MONTH)
            .then(finish => {
              if (finish) {
                this.current_month = this.$store.getters.getCurrentMonth
                this.current_month_pay = this.$store.getters.getCurrentMonth
                this.fetchDebtHeaders()
                this.fetchPayHeaders()
              }
            })
      })
  }
}
</script>

<style scoped lang="scss">
  .row-card {
    div > div:nth-child(1) {
      font-size: .68rem;
      font-weight: 400;
      color: #757575
    }
    div > div:nth-child(2) {
      font-size: .84rem;
      color: #4a4a4a;

      span:nth-child(1) {
        font-weight: 500;
        color: #2a9d2f;
      }
      span:nth-child(2) {
        font-weight: 500;
        color: #2d2d2d;
      }
    }
  }
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #f5f5f5 !important;
  }
</style>